import React, { useEffect } from "react";
import "./Search.sass";
function Search({ value, placeholder, onChange, events }) {
  useEffect(() => {
    document
      .querySelector("input[type=text]")
      .style.setProperty("--c", `${events.search_input_color}`);
  }, [events.search_input_color]);

  return (
    <div className="search">
      <input
        style={{
          color: events.search_input_color,
          backgroundColor: events.search_input_background,
        }}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <button onClick={() => onChange("")}>✕</button>
    </div>
  );
}

export default Search;
