import React, { Component } from "react";
import "./App.sass";
import Content from "./Content";
import Events from "./Events";
import Search from "./Search";
import Header from "./Header";

class App extends Component {
  state = {
    events: {
      title: "",
      search_placeholder: "",
      events: [],
    },
    headerTitle: "",
    header_backgroundColor: "",
    page_background_color: "",
    search_term: "",
    header_font_color: "",
  };
  componentDidMount() {
    fetch("Events.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            events: data,
            headerTitle: data.header_title,
            header_backgroundColor: data.header_backgroundColor,
            header_font_color: data.header_font_color,
          },
          () => {
            document.title = this.state.events.title;
          }
        );
      });
  }

  handleSearch = (value) => {
    this.setState({ search_term: value });
  };

  render() {
    const {
      events,
      headerTitle,
      search_term,
      header_backgroundColor,
      header_font_color,
    } = this.state;
    const { events: eventsArray, title, search_placeholder, ...rest } = events;
    const filtered_events = events.events.filter((i) => {
      return i.title.toLowerCase().indexOf(search_term.toLowerCase()) !== -1;
    });
    return (
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: events.page_background_color,
        }}
      >
        <Header
          color={header_font_color}
          backgroundColor={header_backgroundColor}
          headerTitle={headerTitle}
        />
        <div className="app">
          <Content>
            <Search
              events={events}
              value={search_term}
              placeholder={events.search_placeholder}
              onChange={this.handleSearch}
            />
            <Events eventsStyles={rest} events={filtered_events} />
          </Content>
        </div>
      </div>
    );
  }
}

export default App;
